import styled, { keyframes } from "styled-components";
import { Box } from "@mui/material";

const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

export const PartnerSection = styled.section`
  width: 100%;
  padding: 2rem 1rem;
  margin-bottom: 6rem;
  box-sizing: content-box;

  @media (max-width: 768px) {
    padding: 1rem;
    margin-bottom: 4rem;
  }
`;

export const PartnerContainer = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  background: linear-gradient(135deg, #1a1a2e, #16213e);
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, #00d285, #00bfff, #ff0099, #00d285);
    background-size: 200% 100%;
    animation: ${shimmer} 3s infinite linear;
  }

  @media (max-width: 768px) {
    padding: 2.5rem 1rem;
    border-radius: 1.5rem;
  }
`;

export const PartnerContainerChild = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 800px;
  box-sizing: content-box;
  &::after {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    background: radial-gradient(
      circle,
      rgba(0, 191, 255, 0.1) 0%,
      transparent 70%
    );
    border-radius: 50%;
    z-index: -1;
    animation: ${float} 6s ease-in-out infinite;
  }

  @media (max-width: 768px) {
    gap: 2rem;
  }
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  text-align: center;
  margin: 0;
  background: linear-gradient(135deg, #00d285, #00bfff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  padding: 0 1rem;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, #00d285, #00bfff);
    border-radius: 3px;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  max-width: 600px;
  line-height: 1.6;
  margin: 0;
  padding: 0 1rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    line-height: 1.5;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const ImageBox = styled(Box)`
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid transparent;
    border-radius: 2rem;
    background: linear-gradient(135deg, #00d285, #00bfff) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 1.5rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }
  }

  @media (max-width: 768px) {
    max-width: 320px;
    padding: 0.75rem;

    &::before {
      border-radius: 1.25rem;
    }

    img {
      border-radius: 1rem;
    }
  }
`;

export const StyledButton = styled.button`
  background: linear-gradient(135deg, #00d285, #00bfff);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 3rem;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  width: auto;
  min-width: 200px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 191, 255, 0.3);

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(-1px);
  }

  @media (max-width: 768px) {
    padding: 0.875rem 2.5rem;
    font-size: 0.95rem;
  }

  @media (max-width: 480px) {
    padding: 0.75rem 2rem;
    font-size: 0.9rem;
    min-width: 180px;
  }
`;

import React, { useState, useEffect } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Gift,
  Code,
  Server,
  Database,
  Shield,
  Globe,
  Users,
  Rocket,
} from "lucide-react";
import {
  Badge,
  CTAButton,
  Dot,
  Dots,
  GlowEffect,
  IconContainer,
  NavButton,
  Slide,
  SlideItem,
  SlideItems,
  SlideText,
  SlideTitle,
  SliderContainer,
  SliderWrapper,
} from "./OfferStyles";
import OfferForm from "./OfferForm";
import Navbar from "../../components/shared-components/navbar/Navbar";
import Footer from "../../components/shared-components/footer/Footer";
export const slides = [
  {
    id: 1,
    // imgSrc: offerTalk,
    text: "N800k Worth Giveaway for 10 Business Owners:",
  },
  {
    id: 2,
    // imgSrc: weboffer,
    text: "Enjoy 20% Off Our Full Web Development Service",
  },
  {
    id: 3,
    // imgSrc: cloudOffer,
    text: "Cloud Offer: 50% OFF for First Time Customers!",
  },
  {
    id: 4,
    // imgSrc: graphicsDesigner,
    text: "Unleash the Power of Your Brand @ just 105,000 NGN",
  },
  {
    id: 5,
    // imgSrc: uiOffer,
    text: "100% Discount on UI/UX Design for Your Startup Idea!",
    details: [
      "Are you a startup or small business looking to launch your website or app? We are offering free UI/UX design services to help you create a stunning and functional user experience.",
      "Tailored designs to match your brand identity",
      "20 User Interface Design Screens",
      "Responsive layouts for web and mobile",
      "Offer ends 31st of March 2025. Let’s bring your vision to life!",
    ],
  },
  {
    id: 6,
    // imgSrc: busOpti,
    text: "Business Optimization",
  },
  {
    id: 7,
    // imgSrc: aiautomation,
    text: "AI-Powered Marketing",

    footnote:
      "Take advantage of this limited-time discount to stay ahead in your market.",
  },
  {
    id: 8,
    // imgSrc: aiStartup,
    text: "AI for Startups",
  },
];

const offers = [
  {
    title: "N800k Worth Giveaway for 10 Business Owners",
    items: [
      {
        icon: <Code />,
        text: "Landing Page Design (₦50,000 FREE) – A stunning, professional design tailored to your brand.",
      },
      {
        icon: <Server />,
        text: "Hosting for 6 months (₦10,000 FREE) – Reliable, fast, and secure hosting to keep your site running smoothly.",
      },
      {
        icon: <Database />,
        text: "Database Setup (₦20,000 FREE) – Ensure seamless data management for your business.",
      },
      {
        icon: <Shield />,
        text: "Security Implementation (₦20,000 FREE) – Robust measures to safeguard your online presence.",
      },
    ],
  },
  {
    title: "Enjoy 20% Off Our Full Web Development Service",
    items: [
      {
        icon: <Globe />,
        text: "Custom Designs – Tailored to your brand and audience.",
      },
      { icon: <Users />, text: "Mobile-Friendly – Look great on any device." },
      {
        icon: <Shield />,
        text: "Fast & Secure – Speed and security guaranteed.",
      },
      {
        icon: <Database />,
        text: "SEO-Optimized – Help your website rank higher on search engines.",
      },
      {
        icon: <Server />,
        text: "5 Business Emails – Email addresses branded for your business.",
      },
      { icon: <Rocket />, text: "2 Free Expert Consultation Sessions." },
    ],
  },
  {
    title: "Cloud Offer: 50% OFF for First Time Customers!",
    items: [
      {
        icon: <Server />,
        text: "30% OFF on cloud server setup and configuration for first-time customers.",
      },
      {
        icon: <Shield />,
        text: "Security Optimization Included – Robust configurations to protect your data.",
      },
      { icon: <Rocket />, text: "Launch Your Cloud Journey Today Now!" },
    ],
  },
  {
    title: "Unleash the Power of Your Brand @ just 105,000 NGN",
    items: [
      { icon: <Gift />, text: "Custom Brand Identity Creation." },
      {
        icon: <Code />,
        text: "Bespoke Logo Design that embodies your essence.",
      },
      {
        icon: <Globe />,
        text: "Tailored Color Palette and Typography for lasting impressions.",
      },
      {
        icon: <Server />,
        text: "Professional Business Card & Stationery Designs.",
      },
      { icon: <Users />, text: "1 Free Expert Brand Strategy Consultancy." },
    ],
  },
  {
    title: "100% Discount on UI/UX Design for Your Startup Idea!",
    items: [
      {
        icon: <Code />,
        text: "Tailored designs to match your brand identity.",
      },
      { icon: <Globe />, text: "20 User Interface Design Screens." },
      { icon: <Shield />, text: "Responsive layouts for web and mobile." },
      {
        icon: <Rocket />,
        text: "Offer ends 31st of March, 2025 – Let’s bring your vision to life!",
      },
    ],
  },
  {
    title: "Business Optimization – Streamline with AI",
    items: [
      {
        icon: <Database />,
        text: "Predictive Analytics – Forecast sales, customer behavior, and market trends.",
      },
      {
        icon: <Rocket />,
        text: "Automation Tools – Reduce manual tasks with AI-driven workflows.",
      },
      {
        icon: <Users />,
        text: "Chatbot – Enhance customer support with intelligent, 24/7 assistance.",
      },
    ],
  },
  {
    title: "AI-Powered Marketing – Elevate Your Strategy",
    items: [
      {
        icon: <Gift />,
        text: "Personalized Recommendations – Increase conversions with tailored product suggestions.",
      },
      {
        icon: <Code />,
        text: "Content Generation – Automate blog posts, emails, and ad copy creation.",
      },
      {
        icon: <Database />,
        text: "Sentiment Analysis – Understand customer feedback and improve engagement.",
      },
    ],
  },
  {
    title: "AI for Startups – Affordable Solutions",
    items: [
      {
        icon: <Shield />,
        text: "Customer Insights – Learn more about your users with AI-powered analytics.",
      },
      {
        icon: <Globe />,
        text: "Fraud Detection – Protect your business with robust AI security solutions.",
      },
      {
        icon: <Rocket />,
        text: "Prototype AI Models – Test ideas faster with AI-driven prototypes.",
      },
    ],
  },
];

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleCta = () => {
    const formCont = document.getElementById("offerFormId");
    formCont?.scrollIntoView({ behavior: "smooth" });
  };

  const nextSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentSlide((prev) => (prev + 1) % offers.length);
    setTimeout(() => setIsAnimating(false), 600);
  };

  const prevSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setCurrentSlide((prev) => (prev - 1 + offers.length) % offers.length);
    setTimeout(() => setIsAnimating(false), 600);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 40000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SliderContainer>
      <Navbar />

      <GlowEffect />
      <SliderWrapper>
        <Slide $isAnimating={isAnimating}>
          <Badge>
            <Gift size={16} />
            <span>Special Offer</span>
          </Badge>
          <SlideTitle>{offers[currentSlide].title}</SlideTitle>
          <SlideItems>
            {offers[currentSlide].items.map((item, idx) => (
              <SlideItem key={idx}>
                <IconContainer>{item.icon}</IconContainer>
                <SlideText>{item.text}</SlideText>
              </SlideItem>
            ))}
          </SlideItems>
          <CTAButton onClick={handleCta}>Get Offer Now</CTAButton>
          <NavButton $left $isAnimating={isAnimating} onClick={prevSlide}>
            <ChevronLeft size={24} />
          </NavButton>
          <NavButton $isAnimating={isAnimating} onClick={nextSlide}>
            <ChevronRight size={24} />
          </NavButton>
          <Dots>
            {offers.map((_, idx) => (
              <Dot
                key={idx}
                $active={idx === currentSlide}
                onClick={() => setCurrentSlide(idx)}
              />
            ))}
          </Dots>
        </Slide>
      </SliderWrapper>

      <section
        style={{ marginTop: "2rem", width: "98%", position: "relative" }}
        id="offerFormId"
      >
        <OfferForm />
      </section>

      <Footer />
    </SliderContainer>
  );
}

export default App;

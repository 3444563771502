import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import intgrate from "../../../assets/images/intiailogo.png";
import {
  PartnerSection,
  PartnerContainer,
  PartnerContainerChild,
  Title,
  Subtitle,
  ImageBox,
  StyledButton,
} from "./Partner.styles";

const Partner = () => {
  return (
    <PartnerSection>
      <PartnerContainer>
        <PartnerContainerChild>
          <motion.div
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            viewport={{ once: true }}
          >
            <Title>Meet Our Partner!</Title>
          </motion.div>

          <ImageBox
            as={motion.div}
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <img src={intgrate} alt="Partners" />
          </ImageBox>

          <motion.div
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            viewport={{ once: true }}
          >
            <Subtitle>
              Join forces with industry leaders and innovators. Together, we
              create extraordinary solutions that push boundaries and define the
              future.
            </Subtitle>
          </motion.div>

          <motion.div
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            viewport={{ once: true }}
          >
            <a
              target="_blank"
              href="https://integr8-ai.com/"
              style={{ textDecoration: "none" }}
            >
              <StyledButton>VISIT INTEGR8AI</StyledButton>
            </a>
          </motion.div>
        </PartnerContainerChild>
      </PartnerContainer>
    </PartnerSection>
  );
};

export default Partner;

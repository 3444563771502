import styled from "styled-components";

export const FormContainer = styled.div`
  padding: 40px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: rgb(245, 245, 245);
  margin-top: 7rem;
  border-radius: 10px;

  box-shadow: 0px 0px 40px rgba(16, 185, 129, 0.2),
    0px 0px 80px rgba(6, 182, 212, 0.2);
inset: calc();
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  margin-bottom: 5rem;

  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease;
  &:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 123, 255, 0.6),
      0 0 40px rgba(255, 0, 150, 0.5);
    transform: translateY(-5px);
  }

  @media (max-width: 480px) {
    padding: 10px;
    width: 90%;
  }
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  color: #1e1e1e;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

export const Input = styled.input`
  padding: 14px;
  width: 90%;
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fafafa;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &::placeholder {
    color: #b3b3b3;
    font-size: 0.9rem;
  }
`;

export const Textarea = styled.textarea`
  padding: 14px;
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fafafa;
  resize: vertical;
  transition: all 0.3s ease;

  &:focus {
    border-color: #007bff;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &::placeholder {
    color: #b3b3b3;
    font-size: 0.9rem;
  }
`;

export const Select = styled.select`
  padding: 14px;
  font-size: 1rem;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
  }
`;

export const SubmitButton = styled.button`
  padding: 14px;
  background: linear-gradient(90deg, #10b981, #06b6d4);
  color: #fff;
  border: none;
  width: 100%;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0px 8px 20px rgba(6, 182, 212, 0.5);
  }

  &:active {
    background-color: #3e8e41;
  }
`;

export const HelperText = styled.p`
  font-size: 0.85rem;
  color: #555;
  margin-top: -15px;
  margin-bottom: 15px;
`;

import styled, { css } from "styled-components";

export const SliderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, #1a202c, #2d3748);
  box-sizing: border-box !important;
`;

export const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 80rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  box-sizing: border-box !important;

  // Small to mid screens
  @media (max-width: 767px) {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const GlowEffect = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background: linear-gradient(
    135deg,
    rgba(16, 185, 129, 0.2),
    rgba(6, 182, 212, 0.2)
  );
  filter: blur(80px);
  pointer-events: none;
`;

export const Slide = styled.div`
  position: relative;
  margin-top: 2rem;
  backdrop-filter: blur(20px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2rem;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  min-width: 100%;
  max-width: 60rem;
  transition: transform 0.5s ease;
  transform: ${(props) => (props.$isAnimating ? "scale(0.51)" : "scale(1)")};
  opacity: ${(props) => (props.$isAnimating ? "0.1" : "1")};
  box-sizing: border-box;
  // Small to mid screens
  @media (max-width: 767px) {
    min-width: 90%;
    max-width: 98%;
    width: 95%;
    margin: 1.5rem 0rem;
    padding: 1rem;
  }

  // Large screens
  @media (min-width: 1024px) {
    min-width: 50rem;
    max-width: 50rem;
    margin: 2rem auto;
  }

  // Extra-large screens
  @media (min-width: 1440px) {
    max-width: 60rem;
    padding: 2.5rem;
  }
`;

export const Badge = styled.div`
  position: absolute;
  top: -1rem;
  left: 2rem;
  background: linear-gradient(90deg, #10b981, #06b6d4);
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

export const SlideTitle = styled.h2`
  font-size: 2.5rem; /* Default size for larger screens */
  font-weight: bold;
  background: linear-gradient(90deg, #10b981, #06b6d4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  margin-top: 1.5rem;

  /* Mobile screens */
  @media (max-width: 767px) {
    font-size: 1.5rem; /* Smaller font for mobile */
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  /* Mid-size screens (tablets) */
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 1.8rem;
  }
`;

export const SlideItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SlideItem = styled.div`
  display: flex;
  align-items: start;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const IconContainer = styled.div`
  padding: 0.5rem;
  border-radius: 9999px;
  background: linear-gradient(135deg, #10b981, #06b6d4);
  color: white;
  flex-shrink: 0;
`;

export const SlideText = styled.p`
  color: #e5e7eb;
`;

export const CTAButton = styled.button`
  display: block;
  margin: 2rem auto 0;
  padding: 1rem 2rem;
  background: linear-gradient(90deg, #10b981, #06b6d4);
  color: white;
  font-weight: bold;
  border-radius: 9999px;
  transition: all 0.3s;
  box-shadow: 0px 5px 15px rgba(16, 185, 129, 0.5);
  outline: none;
  border: none;
  cursor: pointer;
  margin-bottom: 3rem;
  &:hover {
    transform: translateY(-0.2rem);
    box-shadow: 0px 8px 20px rgba(6, 182, 212, 0.5);
  }
`;

export const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.1);
  color: white;
  z-index: 100;
  padding: 0.6rem;
  transition: all 0.3s;
  outline: none;
  z-index: 10000;
  border: none;
  border-radius: 50%;

  cursor: pointer;
  opacity: ${(props) => (props.$isAnimating ? "0.5" : "1")};
  pointer-events: ${(props) => (props.$isAnimating ? "none" : "auto")};

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-50%) scale(1.1);
  }

  ${(props) =>
    props.$left
      ? css`
          left: 1rem;
        `
      : css`
          right: 1rem;
        `}
`;

export const Dots = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.5rem;
  z-index: 100;
`;

export const Dot = styled.button`
  width: ${(props) => (props.$active ? "2rem" : "0.5rem")};
  height: 0.5rem;
  border-radius: 9999px;
  background: ${(props) =>
    props.$active
      ? "linear-gradient(90deg, #10b981, #06b6d4)"
      : "rgba(255, 255, 255, 0.3)"};
  transition: all 0.3s;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.$active
        ? "linear-gradient(90deg, #10b981, #06b6d4)"
        : "rgba(255, 255, 255, 0.5)"};
  }
`;

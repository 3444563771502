import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import {
  SubFooterContainer,
  SubFooterContainerChild,
  TextBox,
} from "./subfooter.style";

const Subfooter = () => {
  return (
    <SubFooterContainer>
      <SubFooterContainerChild>
        <TextBox>
          <Typography variant="h4">Do you have a next level-idea?</Typography>
          <Typography variant="body1">
            Reach out and we can create magic together
          </Typography>
        </TextBox>
        <Link to="/contact">
          <Button variant="contained">WORK WITH US</Button>
        </Link>
      </SubFooterContainerChild>
    </SubFooterContainer>
  );
};

export default Subfooter;

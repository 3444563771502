import React, { useState } from "react";
import {
  FormContainer,
  Form,
  Input,
  Label,
  Heading,
  SubmitButton,
  Textarea,
  Select,
} from "./OfferForm.styles";
import { slides } from "./Offer";
import { toast } from "react-toastify";
import LoadingPage from "../../components/shared-components/Loading/Loading";
function OfferForm({ offerType }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    offerDescription: "",
    country: "",
    state: "",
    businessStage: "",
    businessSize: "",
    businessCategory: "",
    offerType: "",
    businessDescription: "",
  });
  const [isLoading, setisLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Invalid email format.";

    if (!formData.contactNumber.trim())
      newErrors.contactNumber = "Contact number is required.";
    else if (!/^\d{10,15}$/.test(formData.contactNumber))
      newErrors.contactNumber = "Invalid contact number format.";

    if (!formData.offerDescription.trim())
      newErrors.offerDescription = "Message is required.";
    if (!formData.country.trim()) newErrors.country = "Country is required.";
    if (!formData.state.trim()) newErrors.state = "State is required.";
    if (!formData.businessSize)
      newErrors.businessSize = "Business size is required.";
    if (!formData.businessCategory)
      newErrors.businessCategory = "Business category is required.";
    if (!formData.businessStage)
      newErrors.businessStage = "Business stage is required.";
    if (!formData.offerType) newErrors.offerType = "Please, select an offer.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const generateEmailHTML = () => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; }
            .section { margin: 20px 0; }
            .highlight { background: #f5f5f5; padding: 15px; }
          </style>
        </head>
        <body>
          <h2>New Offer Request</h2>
          
          <div class="section">
            <h3>Customer Information</h3>
            <div class="highlight">
              <p><strong>Name:</strong> ${formData.name}</p>
              <p><strong>Email:</strong> ${formData.email}</p>
              <p><strong>Phone:</strong> ${formData.contactNumber}</p>
            </div>
          </div>

          <div class="section">
            <h3>Business Details</h3>
            <div class="highlight">
             
              <p><strong>Business Stage:</strong> ${formData.businessStage}</p>
              <p><strong>Business Size:</strong> ${formData.businessSize}</p>
              <p><strong>Offer Category:</strong> ${formData.offerType}</p>
              <p><strong>Location:</strong>  ${formData.state}, ${formData.country}</p>
            </div>
          </div>

      
          <div class="section">
            <h3>Additional Message</h3>
            <div class="highlight">
              <p>${formData.offerDescription}</p>
            </div>
          </div>
        </body>
      </html>
    `;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    setisLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: null,
          subject: formData.name,
          html: generateEmailHTML(),
        }),
      });

      const data = await response.json();
      if (await data) {
        toast.success("Mail sent successfully!");
        setFormData({
          ...formData,
          name: "",
          email: "",
          contactNumber: "",
          offerDescription: "",
          country: "",
          state: "",
          businessSize: "",
          businessCategory: "",
          offerType: "",
          businessDescription: "",

          businessStage: "",
        });
      }
    } catch (err) {
      toast.error("An error occurred while sending mail!");
    } finally {
      setisLoading(false);
    }
  };
  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <FormContainer>

 
      <Heading>Claim Offer</Heading>
      <Form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="name">Name</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your full name"
          />
          {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}
        </div>

        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
          />
          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}
        </div>

        <div>
          <Label htmlFor="contactNumber">Contact Number</Label>
          <Input
            type="tel"
            id="contactNumber"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            placeholder="Enter your contact number"
          />
          {errors.contactNumber && (
            <p style={{ color: "red" }}>{errors.contactNumber}</p>
          )}
        </div>

        <div>
          <Label htmlFor="businessDescription">Business Description</Label>
          <Textarea
            id="businessDescription"
            name="businessDescription"
            value={formData.businessDescription}
            onChange={handleChange}
            placeholder="Describe your business"
          />
        </div>

        <div>
          <Label htmlFor="businessStage">Business Stage</Label>
          <Select
            id="businessStage"
            name="businessStage"
            value={formData.businessStage}
            onChange={handleChange}
          >
            <option value="">Select Business Stage</option>
            <option value="Startup Stage">Startup Stage</option>
            <option value="Pre-Startup/Concept Stage">
              Pre-Startup/Concept Stage
            </option>
            <option value="Established Stage">Established Stage</option>
            <option value="Transition Stage">Transition Stage</option>
            <option value="Expansion Stage">Expansion Stage</option>
            <option value="Renewal Stage">Renewal Stage</option>
            <option value="Growth Stage">Growth Stage</option>
          </Select>
          {errors.businessStage && (
            <p style={{ color: "red" }}>{errors.businessStage}</p>
          )}
        </div>
        <div>
          <Label htmlFor="offer_type">Special offer</Label>
          <Select
            id="offer_type"
            name="offerType"
            value={formData.offerType}
            onChange={handleChange}
          >
            <option value="">Select Offer</option>
            {slides?.map((slide) => (
              <option key={slide.id} value={slide.text}>
                {slide.text}
              </option>
            ))}
          </Select>
          {errors.offerType && (
            <p style={{ color: "red" }}>{errors.offerType}</p>
          )}
        </div>

        <div>
          <Label htmlFor="country">Country</Label>
          <Input
            type="text"
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            placeholder="Enter your country"
          />
          {errors.country && <p style={{ color: "red" }}>{errors.country}</p>}
        </div>
        <div>
          <Label htmlFor="state">State</Label>
          <Input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            placeholder="Enter your state"
          />
          {errors.state && <p style={{ color: "red" }}>{errors.state}</p>}
        </div>
        <div>
          <Label htmlFor="businessSize">Business Size</Label>
          <Select
            id="businessSize"
            name="businessSize"
            value={formData.businessSize}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select your business size
            </option>
            <option value="0-20">0-20</option>
            <option value="21-50">21-50</option>
            <option value="80 & Above">80 & Above</option>
          </Select>
          {errors.businessSize && (
            <p style={{ color: "red" }}>{errors.businessSize}</p>
          )}
        </div>
        <div>
          <Label htmlFor="businessCategory">Business Category</Label>
          <Select
            id="businessCategory"
            name="businessCategory"
            value={formData.businessCategory}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select your business category
            </option>
            <option value="Agriculture and Natural Resources">
              Agriculture and Natural Resources
            </option>
            <option value="Manufacturing and Production">
              Manufacturing and Production
            </option>
            <option value="Retail and Wholesale">Retail and Wholesale</option>
            <option value="Technology">Technology</option>
            <option value="Health and Wellness">Health and Wellness</option>
            <option value="Financial Services">Financial Services</option>
            <option value="Education and Training">
              Education and Training
            </option>
            <option value="Hospitality and Tourism">
              Hospitality and Tourism
            </option>
            <option value="Entertainment and Media">
              Entertainment and Media
            </option>
            <option value="Transportation and Logistics">
              Transportation and Logistics
            </option>
            <option value="Real Estate and Construction">
              Real Estate and Construction
            </option>
            <option value="Professional Services">Professional Services</option>
            <option value="Energy and Utilities">Energy and Utilities</option>
            <option value="Nonprofit and Social Enterprises">
              Nonprofit and Social Enterprises
            </option>
            <option value="Personal Services">Personal Services</option>
            <option value="Fashion and Lifestyle">Fashion and Lifestyle</option>
            <option value="Food and Beverage">Food and Beverage</option>
            <option value="Arts and Crafts">Arts and Crafts</option>
            <option value="Sports and Recreation">Sports and Recreation</option>
            <option value="Automotive">Automotive</option>
            <option value="Clean Tech and Sustainability">
              Clean Tech and Sustainability
            </option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Defense and Security">Defense and Security</option>
          </Select>
          {errors.businessCategory && (
            <p style={{ color: "red" }}>{errors.businessCategory}</p>
          )}
        </div>

        <div>
          <Label htmlFor="offerDescription">Message</Label>
          <Textarea
            id="offerDescription"
            name="offerDescription"
            value={formData.offerDescription}
            onChange={handleChange}
            placeholder="Write us a note"
          />
          {errors.offerDescription && (
            <p style={{ color: "red" }}>{errors.offerDescription}</p>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SubmitButton disabled={isLoading} type="submit">
            Get Offer
          </SubmitButton>
        </div>
      </Form>
    </FormContainer>
  );
}

export default OfferForm;
